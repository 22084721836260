import React from 'react';
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        <span className="font-weight-semibold">{`${APP_NAME}`}</span>
        {` - ${new Date().getFullYear()} - `} Todos os direitos reservados
      </span>
    </footer>
  );
}
