import {
  AppstoreOutlined,
  FileSyncOutlined,
  FontColorsOutlined,
  PieChartOutlined,
  PlaySquareOutlined,
  SolutionOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'sidenav.dashboard',
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'playlist',
    path: `${APP_PREFIX_PATH}/playlist`,
    title: 'sidenav.playlist',
    icon: PlaySquareOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'report',
    path: '',
    submenu: [
      {
        key: 'report.submenu',
        title: 'sidenav.reports',
        icon: FileSyncOutlined,
        path: '',
        breadcrumb: false,
        submenu: [
          {
            key: 'report.athlete',
            path: `${APP_PREFIX_PATH}/report/athlete`,
            title: 'sidenav.reports.athlete',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.coupon',
            path: `${APP_PREFIX_PATH}/report/coupon`,
            title: 'sidenav.reports.coupon',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.free.content',
            path: `${APP_PREFIX_PATH}/report/free-content`,
            title: 'sidenav.reports.free_content',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.wallet.balance',
            path: `${APP_PREFIX_PATH}/report/user-balance`,
            title: 'sidenav.reports.wallet_balance',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.cancellations',
            path: `${APP_PREFIX_PATH}/report/cancellation`,
            title: 'sidenav.reports.cancellation',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.referral.code',
            path: `${APP_PREFIX_PATH}/report/referral-code`,
            title: 'sidenav.reports.referral_code',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'report.referral.bunny',
            path: `${APP_PREFIX_PATH}/report/videos-bunny`,
            title: 'sidenav.reports.bunny',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'category',
    path: `${APP_PREFIX_PATH}/categories`,
    title: 'sidenav.category',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'testimonials',
    path: `${APP_PREFIX_PATH}/testimonials`,
    title: 'sidenav.testimonials',
    icon: SolutionOutlined,
    breadcrumb: false,
    submenu: [],
  },

  // {
  //   key: 'revenue',
  //   path: `${APP_PREFIX_PATH}/revenue`,
  //   title: 'sidenav.revenue',
  //   icon: WalletOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: 'management',
    title: 'sidenav.management',
    path: '/#',
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'management-users',
        title: 'sidenav.users',
        path: `${APP_PREFIX_PATH}/user`,
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'management-students',
        title: 'sidenav.students',
        path: `${APP_PREFIX_PATH}/student`,
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'management-coupons',
        title: 'sidenav.coupons',
        path: `${APP_PREFIX_PATH}/coupon`,
        icon: FontColorsOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
