import authService from 'services/auth.service';
import { LoginInterface } from 'types/auth.interface';
import create from 'zustand';
import { AUTH_TOKEN, USER_LOGGED, ROLE, USER } from '../constants/AuthConstant';

export const useAuth = create(set => ({
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  uuidUser: localStorage.getItem(USER),
  role: localStorage.getItem(ROLE),

  showAuthMessage: (message: string) => {
    set({ message, showMessage: true, loading: false });
  },
  showLoading: () => {
    set({ loading: true });
  },
  hideAuthMessage: () => {
    set({ message: '', showMessage: false });
  },
  authenticated: (token: string) => {
    set({ loading: false, redirect: '/app/home', token: token });
  },
  login: async (body: LoginInterface) => {
    set({ loading: true, showMessage: false });

    try {
      const result = await authService.login(body);
      set({
        token: result.access_token,
        uuidUser: result.uuidUser,
        role: result.role,
      });
      window.localStorage.setItem(AUTH_TOKEN, result.access_token);
      window.localStorage.setItem(USER_LOGGED, result.username);
      window.localStorage.setItem(USER, result.uuidUser);
      window.localStorage.setItem(ROLE, result.role);
    } catch (e: any) {
      console.log(e.response);

      set({ message: e.response.data.message, showMessage: true });
    } finally {
      set({
        loading: false,
      });
    }
  },
  logout: () => {
    set({ token: null });
    window.localStorage.removeItem(AUTH_TOKEN);
    window.location.href = '/auth/login';
  },
}));
