import React, { useState } from 'react';
import { SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { DIR_RTL } from 'constants/ThemeConstant';
import { useTheme } from 'stores/theme.store';

const NavPanel = () => {
  const { direction } = useTheme();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item
          key="panel"
          onClick={() => showDrawer()}
        >
          <span>
            <SettingOutlined className="nav-icon mr-0" />
          </span>
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement={direction === DIR_RTL ? 'left' : 'right'}
        width={350}
        onClose={() => onClose()}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  );
};

export default NavPanel;
